import React from 'react'

export default class extends React.Component {
	render () {
		return (
			<div className="ViewContainer">
				<h1 className="Title">Historia abreviada</h1>
				<div className="HistoryContainer">
				<p>Nuestro grupo se funda en el año 1967, siendo su primer presidente Fernando López Marcos. Su punto de origen se encuentra en el Grupo Scout San Juan de Oviedo, cuyos componentes, además de montañismo, empezaron a practicar <span class="HistoryBold">espeleología y escalada</span>; actividades que entrañaban un cierto riesgo, por lo que deciden crearlo para federarse y de paso tener un seguro. El nombre elegido proviene de un pico cercano a la Peñasanta de Castilla.</p>
				<p>Muy pronto nuestros espeleólogos, junto con otros riosellanos, saltaron a la fama por el descubrimiento de la Cueva prehistórica de Tito Bustillo en abril de 1968; denominada así en honor de uno de sus descubridores, Celestino Fernández Bustillo, fallecido unos días después en un accidente.</p>
				<p>Por su parte, los escaladores, a pesar de su juventud, realizan primeras ascensiones a paredes de gran dificultad, tanto en la Cordillera como en los Picos de Europa; en la que destaca la primera invernal asturiana a la cara oeste del Picu Urriellu.</p>
				<p>Las actividades no sólo se sitúan en Asturias y en otros lugares de España, ya que en la década de los setenta se llevan a cabo dos salidas a los Alpes en las que se sube al Mont Blanc y a otras cumbres. También en esta década algunos miembros participan en dos expediciones a la cordillera andina; concretamente al Nevado Salcantay (6.300 m), cuya cumbre fue lograda en un segundo intento en 1978 por nuestro presidente de honor Antonio Alcalde, entre otros.</p>
				<p>Otra de las actividades que vienen realizando los socios es la del <span class="HistoryBold">esquí de montaña</span>. Inicialmente se practicaba de forma individual, pero dado que el número de aficionados aumentaba tanto en nuestro Grupo con en otros, se decidió organizar en 1983 la I Travesía por la Cordillera Cantábrica; en vista del éxito obtenido, continuó realizándose durante cuatro temporadas más. Por otra parte, la creciente afición de los socios a la <span class="HistoryBold">bicicleta de montaña</span> dio lugar a la I Travesía Cordillera Cantábrica (BTT) en 1990, prueba organizada durante cinco ediciones, y que posteriormente fue asumida por Asturcón BTT. Además del esquí y la bicicleta hemos de incluir las <span class="HistoryBold">carreras de montaña</span>, en las que destacan las féminas del Grupo tanto por sus triunfos como por sus buenas clasificaciones.</p>
				<p>El Torreblanca realiza actividades todos los fines de semana a lo largo de todo el año (excepto julio y agosto), y durante Semana Santa y verano suele hacer salidas fuera de Asturias, tanto por España como por el extranjero.</p>
				<p>Desde el año 1979 tenemos nuestra sede en la Calle del Sol nº 1, 1º de Oviedo, lugar de encuentro de socios y amigos, en el que se llevan a cabo diversas actividades como: como proyecciones, presentaciones de libros, charlas, etc., en las que predomina el buen ambiente; y en las que otros montañeros y también aquellas personas que quieran iniciarse en este deporte son siempre bien recibidos.</p>
				</div>
			</div>
		)
	}
}
