import React from 'react'
import { parseISO, format, isSaturday } from 'date-fns'
import { es as esLocale } from 'date-fns/locale'
import debounce from 'lodash.debounce'
import { RingLoader } from 'react-spinners'
import { axios } from '../helpers'
import { ApplicationContext } from '../contexts'

class HistorialDeSalidas extends React.Component {

	constructor (props) {
		super(props)

		this.state = {
			isLoaded: false,
			error: null,
			query: ''
		}
	}

	componentDidMount () {
		this.refreshActivities();
	}

	componentDidUpdate (prevProps, prevState) {
		if (prevState.query !== this.state.query) {
			this.refreshActivities();
		}
	}

	refreshActivities = debounce(() => {
		const self = this
		axios.get(`/actividades/historial.json?q=${encodeURIComponent(this.state.query)}`).then(function (res) {
			const { activities } = res.data
			activities.forEach(function (activity) {
				activity.starts_at = parseISO(activity.starts_at)
			})
			self.setState({ isLoaded: true, activities })
		}).catch(function (error) {
			self.setState({ isLoaded: true, error })
		})
	}, 250)

	renderRows = () => {
		const self = this
		return this.state.activities.reduce(function (memo, activity) {
			const year = activity.starts_at.getFullYear()
			let firstActivity = false
			if (year !== memo._year) {
				memo.push({ type: 'year-row', year })
				memo._year = year
				firstActivity = true
			}
			memo.push({ type: 'activity-row', activity, first: firstActivity })
			return memo
		}, []).map(function (row) {
			if (row.type === 'year-row') {
				return (<tr key={row.year} className="yearRow">
					<td colSpan={3}><strong>{row.year}</strong></td>
				</tr>)
			} else if (row.type === 'activity-row') {
				return (<tr key={row.activity.id} className={`activityRow ${row.first ? "firstActivity" : undefined}`}>
					<td>{self.formatDate(row.activity.starts_at)}{isSaturday(row.activity.starts_at) ? (<><br /><strong className="Saturday">SÁBADO</strong></>) : null}</td>
					<td>
						<span dangerouslySetInnerHTML={{__html: row.activity.name}} />
					</td>
					<td>
						{row.activity.track_url && (
							<a href={row.activity.track_url} className="ActionLink">track</a>
						)}
					</td>
				</tr>)
			} else {
				throw `Unexpected row type: ${row.type}`
			}
		})
	}

	onSearchQueryChange = (event) => {
		this.setState({ query: event.target.value })
	}

	render () {
		const { error, isLoaded } = this.state
		let content

		if (error) {
			content = <span style={{paddingLeft: '30px'}}>Error: {error.message}</span>
		} else {
			content = (
				<div className="ViewContainer">
					<h1 className="Title">Historial de Salidas</h1>
					{isLoaded && (<>
						<div className="SearchContainer">
							<input type="text" onChange={this.onSearchQueryChange} />
						</div>
						<table className="ProgramacionTable" cellPadding={0} cellSpacing={0}>
							<tbody>{this.renderRows()}</tbody>
						</table>
					</>)}
					{!isLoaded && (
						<div className="LoadingContainer">
							<RingLoader />
						</div>
					)}
				</div>
			)
		}
		return content
	}

	formatDate = (date) => {
		return format(date, "d MMMM", { locale: esLocale, awareOfUnicodeTokens: true })
	}

}

HistorialDeSalidas.contextType = ApplicationContext

export default HistorialDeSalidas
