import React from 'react'

class Tarifas extends React.Component {
	render () {
		return (
			<div className="ViewContainer TarifasContainer">
				<h1 className="Title TarifasTitle">Tarifas 2025</h1>
				<hr className="TarifasDivider" />

				<h2>Salidas de un día</h2>
				<table className="TarifasTable">
				<thead>
					<tr>
					<th></th>
					<th>Socio/a</th>
					<th>No Socio/a</th>
					</tr>
				</thead>
				<tbody>
					<tr>
					<td>INFANTIL</td>
					<td>4 €</td>
					<td>5 €</td>
					</tr>
					<tr>
					<td>JUVENIL</td>
					<td>6 €</td>
					<td>10 €</td>
					</tr>
					<tr>
					<td>ADULTO</td>
					<td>12 €</td>
					<td>17 €</td>
					</tr>
				</tbody>
				</table>

				<h2>Alquiler material</h2>
				<table className="TarifasTable">
				<thead>
					<tr>
					<th calssName="col1"></th>
					<th calssName="coln">Socio/a</th>
					<th calssName="coln">No Socio/a</th>
					</tr>
				</thead>
				<tbody>
					<tr>
					<td>CRAMPONES</td>
					<td>3 €</td>
					<td>5 €</td>
					</tr>
					<tr>
					<td>PIOLET</td>
					<td>2 €</td>
					<td>4 €</td>
					</tr>
				</tbody>
				</table>

				<h2>Cuotas anuales</h2>
				<table className="TarifasTable">
				<tbody>
					<tr>
					<td>INFANTIL</td>
					<td>10 €</td>
					</tr>
					<tr>
					<td>JUVENIL</td>
					<td>15 €</td>
					</tr>
					<tr>
					<td>ADULTO</td>
					<td>30 €</td>
					</tr>
				</tbody>
				</table>
				<hr className="TarifasDivider" />

				<ul className="TarifasNotes">
					<li>Se alquila material solo para salidas del grupo.</li>
					<li>INFANTIL menos de 14 años.</li>
					<li>JUVENIL menos de 26 años.</li>
				</ul>
			</div>
		)
	}
}

export default Tarifas
