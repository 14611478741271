import React from 'react'
import { parseISO, format, isSaturday } from 'date-fns'
import {es as esLocale} from 'date-fns/locale'
import { Link } from 'react-router-dom'
import { axios } from '../helpers'
import { ApplicationContext } from '../contexts'

class Programacion extends React.Component {

	constructor (props) {
		super(props)

		this.state = {
			isLoaded: false,
			error: null
		}
	}

	componentDidMount () {
		const self = this
		axios.get(`/actividades.json?year=${this.props.match.params.year}`).then(function (res) {
			const { activities } = res.data
			activities.forEach(function (activity) {
				activity.starts_at = parseISO(activity.starts_at)
				activity.ends_at = parseISO(activity.ends_at)
			})
			self.setState({ isLoaded: true, activities })
		}).catch(function (error) {
			self.setState({ isLoaded: true, error })
		})
	}

	renderRows = () => {
		const self = this
		const year = this.props.match.params.year
		const { admin } = this.context
		return this.state.activities.reduce(function (memo, activity) {
			const month = activity.starts_at.getMonth()
			let firstActivity = false
			if (month !== memo._month) {
				memo.push({ type: 'month-row', month: month })
				memo._month = month
				firstActivity = true
			}
			memo.push({ type: 'activity-row', activity: activity, first: firstActivity })
			return memo
		}, []).map(function (row) {
			if (row.type === 'month-row') {
				return (<tr key={row.month} className="monthRow">
					<td colSpan={admin ? 3 : 2}>{self.formatMonth(new Date(year, row.month, 1))}</td>
				</tr>)
			} else if (row.type === 'activity-row') {
				return (<tr key={row.activity.id} className={`activityRow ${row.first ? "firstActivity" : undefined}`}>
					<td>{self.formatDate(row.activity.starts_at)}{isSaturday(row.activity.starts_at) ? (<><br /><strong className="Saturday">SÁBADO</strong></>) : null}</td>
					<td>
						{row.activity.activity_type === 'excursion' && (
							<Link to={`/salidas/${row.activity.id}`}>
								<span dangerouslySetInnerHTML={{__html: row.activity.name}} />
							</Link>
						)}
						{row.activity.activity_type === 'projection' && (
							<Link to={`/eventos/${row.activity.id}`}>
								<span dangerouslySetInnerHTML={{__html: row.activity.name}} />
							</Link>
						)}
					</td>
					{admin && (
						<td>
							<a href={`/actividades/${row.activity.id}/edit`} className="ActionLink">Editar</a>
						</td>
					)}
				</tr>)
			} else {
				throw `Unexpected row type: ${row.type}`
			}
		})
	}

	render () {
		const { error, isLoaded } = this.state
		const { match } = this.props
		let content

		if (error) {
		  content = <span>Error: {error.message}</span>
		} else if (!isLoaded) {
		  content = <span>Cargando ...</span>
		} else {
			content = (
				<div className="ViewContainer">
					<h1 className="Title">Programación {match.params.year}</h1>
					<table className="ProgramacionTable" cellPadding={0} cellSpacing={0}>
						<tbody>{this.renderRows()}</tbody>
					</table>
				</div>
			)
		}
		return content
	}

	formatDate = (date) => {
		// return format(date, "eeee',' d 'de' MMMM 'de' yyyy", { locale: esLocale, awareOfUnicodeTokens: true })
		return format(date, "d MMMM", { locale: esLocale, awareOfUnicodeTokens: true })
	}

	formatMonth = (date) => {
		return format(date, "MMMM", { locale: esLocale, awareOfUnicodeTokens: true })
	}

}

Programacion.contextType = ApplicationContext

export default Programacion
