import React from 'react'

const email = "grupo@gmtorreblanca.com"

export default class extends React.Component {
	render () {
		return (
			<div className="ViewContainer">
				<h1 className="Title">Aviso Legal y Política de Privacidad - v1.3 nov 2013</h1>

				<div className="AvisoLegalContainer">
				<h2>1. DATOS DEL TITULAR DE LA PÁGINA</h2>
				<p>De conformidad con lo establecido en la Ley 34/2,002 de Servicios de la Sociedad de la Información y de Comercio Electrónico se informa que Grupo de Montaña Torreblanca (en adelante GMTorreblanca) es el titular del website cuyo nombre de dominio es <a href="https://www.gmtorreblanca.com/" className="Link">www.gmtorreblanca.com</a> y la dirección de correo electrónico es <a href={`mailto:${email}`} className="Link">{email}</a>.</p>
				<p>La sede social de GMTorreblanca se ubica en la Plaza del Sol n&deg; 1, 1&deg;. 33009 Oviedo (Asturias) siendo su C.I.F. G33301698.</p>

				<h2>2. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
				<p>El código fuente, los diseños gráficos, imágenes, fotografías, sonidos, animaciones, software, textos, así como la información y en definitiva los elementos contenidos en la website están protegidos por la legislación española sobre los derechos de propiedad intelectual e industrial a favor de GMTorreblanca, si bien el usuario podrá visualizarlos y utilizarlos para uso personal, quedando prohibida su utilización con fines comerciales y en consecuencia no estando permitida la reproducción y/o publicación, total o parcial, ni su tratamiento informático, distribución, difusión, modificación, transformación o descompilación, etc., sin el permiso previo y por escrito del titular.</p>

				<h2>3. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2>
				<p>De conformidad con lo prevenido en la Ley Orgánica 15/1.999 de Protección de Datos de Carácter Personal, se informa que determinadas áreas de la Web solicitan al usuario una serie de datos de carácter personal que se incorporan a los ficheros creados y mantenidos bajo responsabilidad de GMTorreblanca con domicilio antedicho, ficheros registrados en la Agencia Española de Protección de Datos y cuya finalidad es la prestación de servicios de la empresa, resolución de consultas on-line y acciones publicitarias, siendo los campos solicitados en cada formulario de obligado cumplimiento para el acceso a prestación del servicio respectivo de cada área, realización de estadísticas, consultas y solicitud de información, actos y eventos, siendo los campos solicitados en cada formulario de obligado cumplimiento para la acceso a la información y/o contenidos de las respectivas áreas que lo solicitan. Dichos datos son facilitados por el usuario quien responde de su veracidad y supone prestado el consentimiento para el tratamiento de los datos con las finalidades indicadas y propias de cada fichero.</p>
				<p>Dichos datos serán igualmente tratados de conformidad con las prevenciones impuestas en la Ley Orgánica 15/1.999 en favor del interesado y salvo la cesión de los mismos a la autoridades cuando así se exija en una Ley, dichos datos no serán cedidos ni comunicados a terceros sin el consentimiento previo del interesado, el cual podrá acceder a dicho fichero, con la finalidad de obtener información, rectificarla, cancelarla u oponerse a ella en la dirección de GMTorreblanca acompañando fotocopia de su D.N.I. o bien mediante correo electrónico firmado digitalmente al objeto de acreditar su identidad dirigido a <a href={`mailto:${email}`} className="Link">{email}</a>.</p>

				<h2>4. USO DE COOKIES</h2>
				<p>Esta página usa Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google. Se puede rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada del navegador, sin embargo, debe saber que si lo hace puede suceder que no pueda usar la plena funcionabilidad de este website. Al utilizar este website consiente el tratamiento de información por Google en la forma y para los fines arriba indicados.</p>

				<h2>5. MEDIDAS TECNICAS Y ORGANIZATIVAS</h2>
				<p>GMTorreblanca ha adoptado las medidas técnicas y organizativas conforme a lo dispuesto en la normativa vigente, siendo los niveles de seguridad los adecuados a los datos que se facilitan y, además, se han instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración y acceso no autorizado a los datos que nos facilitan.</p>

				<h2>6. EXENCIÓN DE RESPONSABILIDAD</h2>
				<p>GMTorreblanca no se hace responsable de cualquier pérdida, directa o indirecta, irrogada como consecuencia de decisiones adoptadas en base a la información contenida en la web de su titularidad, dado que por demás, la misma, así como sus contenidos pueden ser variados, modificados y/o suprimidos en cualquier momento sin necesidad legal de previo aviso.</p>
				<p>Disponemos de enlaces y conexiones con otros lugares y páginas Web sin que por ello podamos responder de la calidad, exactitud y veracidad de los contenidos de las mismas por lo que no se hace responsable de cualquier pérdida, directa o indirecta, irrogada como consecuencia de decisiones adoptadas en base a la información insertada en las citadas página.</p>

				<h2>7. LEGISLACIÓN APLICABLE Y TRIBUNALES COMPETENTES</h2>
				<p>La legislación aplicable es la española y se declara jurisdicción competente para la resolución de cualquier tipo de controversia que se pudiera derivar de la utilización de esta Web así como de la contratación que se pudiera realizar a través de ella a los Juzgado y Tribunales de Oviedo.</p>
				<p>En caso de duda, sugerencia o comentario contacte con nosotros mediante el correo electrónico en <a href={`mailto: ${email}`} className="Link">{email}</a>.</p>
				</div>
			</div>
		)
	}
}
