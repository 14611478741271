import React from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { disableScroll, enableScroll } from '../helpers';
import { ApplicationContext } from '../contexts';

import Shield from '../shield';
import FacebookLogo from './f_logo_RGB-Blue_58.png';
import InstagramLogo from './IG_Glyph_Fill.png';

class NavigationMenu extends React.Component {
	render() { return <div />; }
	state = {
		showMenu: false
	};

	toggleMenu = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const showMenu = !this.state.showMenu;
		this.setState({ showMenu });

		showMenu ? disableScroll() : enableScroll();
	};

	hideMenu = () => {
		this.setState({ showMenu: false });
		enableScroll();
	};

	showHistoria = () => {
		this.hideMenu();
	};

	render() {
		const { admin } = this.context
		const onPortada = this.props.match.path === "/" && this.props.match.isExact;

		return (
			<>
				{this.state.showMenu && (
					<div className="NavigationMenuContainer">
						<div className="ViewContainer">
							<ul className="NavigationMenu">
								{document.location.pathname !== '/' && (
									<li><NavLink exact to="/" onClick={this.hideMenu}>Portada</NavLink></li>
								)}
								<li><NavLink to="/salidas/proxima" onClick={this.hideMenu}>Próxima Salida</NavLink></li>
								<li><NavLink to="/programacion/2025" onClick={this.hideMenu}>Programación 2025</NavLink></li>
								<li><NavLink to="/historial" onClick={this.hideMenu}>Historial de Tracks</NavLink></li>
								<li><NavLink to="/tarifas" onClick={this.hideMenu}>Tarifas</NavLink></li>
							{/*	<li><NavLink to="/media" onClick={this.hideMenu}>Foto y Vídeo</NavLink></li> */}
								<li><NavLink to="/contacto" onClick={this.hideMenu}>Contacto</NavLink></li>
								<li><NavLink to="/directiva" onClick={this.hideMenu}>Directiva</NavLink></li>
								<li><NavLink to="/historia" onClick={this.hideMenu}>Historia</NavLink></li>
								<li><NavLink to="/legal" onClick={this.hideMenu}>Aviso Legal</NavLink></li>
								<li><NavLink to="/admin" onClick={this.hideMenu}>Site Admin</NavLink></li>
							</ul>
							<div className="ShieldContainer">
								<div className="FacebookLink">
									<a href="https://www.facebook.com/grupo.montanatorreblancaoviedo" target="_blank">
										<img src={FacebookLogo} />
									</a>
								</div>
								<Link to="/historia"><Shield onClick={this.showHistoria} /></Link>
								<div className="InstagramLink">
									<a href="https://www.instagram.com/g.m.torreblanca" target="_blank">
										<img src={InstagramLogo} />
									</a>
								</div>
							</div>
							<div className="FooterNote">
								Grupo subvencionado por el Excmo. Ayuntamiento de Oviedo y la Federación de deportes de montaña, escalada y senderismo del Principado de Astutias
							</div>
						</div>
					</div>
				)}

				<div className={`NavigationMenuToggle ${this.state.showMenu ? "NavigationMenuOpen" : undefined} ${onPortada ? "OnPortada" : undefined}`} onClick={this.toggleMenu}>
					<div className="bar1"></div>
					<div className="bar2"></div>
					<div className="bar3"></div>
				</div>
			</>
		);
	}
}

NavigationMenu.contextType = ApplicationContext

export default withRouter(NavigationMenu);
